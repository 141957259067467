import { state, mutations, actions, getters } from '@/store/build-module/common';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';
import store from '@/store/store';

const module = 'partnerAllocations';

const meta = {
	module,
	basePath: '/v1.0/partner_allocations',
	mapping: {
		start_time: 'startTime',
		end_time: 'endTime',
		system_id: 'systemId',
		station_id: 'stationId'
	},
	dateFields: ['start_time', 'end_time'],
	scope: await getScope('kogs'),
	inputWrapper: input => ({ data: input })
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'find'])
};

const extendAllocation = allocation => {
	const { startTime, endTime } = allocation;
	const duration = Math.round(endTime.toSeconds() - startTime.toSeconds());
	const system = store.getters['systems/find'](allocation.systemId);
	// startTimepadded and endTimePadded is assigned the corrosponding xTime since the xTimePadded values is nedded in conflictAnalyse.
	return {
		...allocation,
		system,
		startTimePadded: startTime,
		endTimePadded: endTime,
		duration,
		durationPadded: duration,
		setupDuration: 0,
		teardownDuration: 0,
		isPartnerAllocation: true
	};
};

const baseGetters = getters(module);
const baseActions = actions(api);

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...baseGetters,

		all(state, getters, rootState) {
			return baseGetters.all(state, getters, rootState)
				.map(extendAllocation);
		}
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...baseActions
	}
};
