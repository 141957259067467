import { keys } from "./safe";

type Tier = "tier0" |
	"tier1" |
	"tier2" |
	"tier3" |
	"tierLeop";

type TierProps = { name: string, days: number, seconds?: number }

type Tiers = { [key in Tier]: TierProps }

const tiers: Tiers = {
	tier0: { name: "Tier 0", days: 9 },
	tier1: { name: "Tier 1", days: 7 },
	tier2: { name: "Tier 2", days: 5 },
	tier3: { name: "Tier 3", days: 3 },
	tierLeop: { name: "Leop", days: 10 }
};

keys(tiers).forEach(
	(key) => tiers[key].seconds = tiers[key].days * 24 * 60 * 60
);

const contactCart = {
	createdContacts: [],
	updatedContacts: [],
	movedContacts: [],
	trimmedContacts: [],
	cancelledContacts: []
};

Object.freeze(contactCart);

const bandsDefinitions = {
	types: [
		{ name: "X-band", id: "X" },
		{ name: "S-band", id: "S" },
		{ name: "KA-band", id: "KA" },
		{ name: "L-band", id: "L" },
		{ name: "UHF-band", id: "UHF" },
		{ name: "C-band", id: "C" }
	],
	polarizations: [
		{ name: "LHCP", id: "LHCP" },
		{ name: "RHCP", id: "RHCP" },
		{ name: "EITHER", id: "EITHER" },
		{ name: "DUAL", id: "DUAL" },
		{ name: "HORIZONTAL", id: "HORIZONTAL" }
	],
	directions: [
		{ name: "UP", icons: ["arrow/up"], id: "UP" },
		{ name: "DOWN", icons: ["arrow/down"], id: "DOWN" },
		{ name: "BOTH", icons: ["arrow/up", "arrow/down"], id: "BOTH" }
	]
} as const;

const lifecycleStates = {
	STAGED: { name: "In setup", id: "STAGED" },
	CREATED: { name: "Operational", id: "CREATED" },
	DEPRECATED: { name: "Deprecated", id: "DEPRECATED" },
	REMOVED: { name: "Removed", id: "REMOVED" }
} as const;

const contactStates = {
	conflicting: { id: "conflicting", name: "CONFLICTING", icon: "cross" },
	scheduled: { id: "scheduled", name: "SCHEDULED", icon: "calendar" },
	committed: { id: "committed", name: "COMMITTED", icon: "lock" },
	pending: { id: "pending", name: "PENDING", icon: "hourglass" },
	cancelled: { id: "cancelled", name: "CANCELLED", icon: "cross" }
} as const;

const contactSignatureOutcomes = {
	anomaly: { id: "anomaly", name: "ANOMALY", icon: "danger.triangle.filled" },
	remark: { id: "remark", name: "REMARK", icon: "danger" },
	success: { id: "success", name: "SUCCESS", icon: "checkmark" },
	waiting: { id: "waiting", name: "WAITING", icon: "hourglass" },
	witness_required: { id: "witness_required", name: "WITNESS REQUIRED", icon: "danger", class: "witness", text: "Witness Required" }
} as const;

const nonCancelledStates = Object.keys(contactStates)
	.filter((key) => key !== contactStates.cancelled.id)
	.map((s) => s.toUpperCase());

const frontendStates = {
	setup: {
		id: "setup",
		icon: "arrow/revolvingDuo",
		startTimeKey: "startTimePadded",
		endTimeKey: "startTime",
		class: "in-setup",
		text: "In setup mode",
		original: "COMMITTED"
	},
	ongoing: {
		id: "ongoing",
		icon: "ongoing",
		startTimeKey: "startTime",
		endTimeKey: "endTime",
		class: "ongoing",
		text: "Ongoing",
		original: "COMMITTED"
	},
	teardown: {
		id: "teardown",
		icon: "arrow/revolvingDuo",
		startTimeKey: "endTime",
		endTimeKey: "endTimePadded",
		class: "teardown",
		text: "In teardown mode",
		original: "COMMITTED"
	},
	insigning: {
		id: "insigning",
		icon: "signing",
		class: "in-signing",
		text: "In signing",
		original: "COMMITTED"
	}
} as const;

const principalKinds = {
	individual: { icon: "profile", id: "individual", name: "User" },
	system: { icon: "cog", id: "system", name: "System" },
	key: { icon: "maintenance", id: "key", name: "Api key" }
} as const;

const principalTags = {
	'partner': 'Partner',
	'ksat-system': 'KSAT System',
	'internal': 'Internal'
} as const;

export type Keys<T> = keyof T;
export type Values<T> = T[Keys<T>]

const apiServerNameMapping = {
	"api-kogs": "Ground network",
	"api-heimdall": "Authorization management",
	"api-tyr": "Ephemeris management"
} as const;

const systemResourcePermissions = [
	"ks3-view",
	"ks3-modify"
] as const;

const spacecraftDefinitions = {
	catalogNumberAssignments: [
		{ id: "PERMANENT", name: "Permanent" },
		{ id: "TEMPORARY", name: "Temporary" },
		{ id: "DUMMY", name: "Dummy" }
	],
	kinds: [
		{ id: "SATELLITE", name: "Satellite" },
		{ id: "LAUNCHER", name: "Launcher" },
		{ id: "RELAY", name: "Relay" }
	],
	orbits: [
		{ id: "SSO", name: "SSO" },
		{ id: "POLAR", name: "POLAR" },
		{ id: "LEO", name: "LEO" },
		{ id: "MEO", name: "MEO" },
		{ id: "GEO", name: "GEO" },
		{ id: "HEO", name: "HEO" },
		{ id: "GSO", name: "GSO" },
		{ id: "LUNAR", name: "LUNAR" },
		{ id: "LAGRANGE", name: "LAGRANGE" },
		{ id: "NA", name: "NA" }
	]
} as const;

const horizonProfile = {
	numberOfDegrees: 360,
	datasets: {
		horizon: { key: "horizon", name: "Horizon", color: "#7cd6fd" },
		uplink: { key: "uplink", name: "Uplink", color: "#743ee2" },
		newValues: { name: "New", color: "#7cd6fd" },
		oldValues: { name: "Old", color: "#743ee2" },
		difference: { name: "Difference", color: "#e23c60" }
	}
} as const;

const azimuthFormat = {
	datasets: {
		azimuth: { key: "azimuth", name: "Azimuth", color: "#e5ae56" },
		timestamp: { key: "timestamp", name: "Timestamp", color: "#e23c60" }
	}
} as const;

const trackingPredictionFormat = {
	datasets: {
		timestamp: { key: "timestamp", name: "Timestamp", color: "#e23c60" },
		horizon: { key: "horizon", name: "Horizon", color: "#7cd6fd" },
		uplink: { key: "uplink", name: "Uplink", color: "#743ee2" },
		elevation: { key: "elevation", name: "Pred.Elevation", color: "#2b990f" }
	}
} as const;

const trackingPredictionSummaryFormat = {
	'aos': 'AOS',
	'los': 'LOS',
	'twoDegUp': '2° up',
	'twoDegDown': '2° down',
	'uplinkUp': 'Uplink up',
	'uplinkDown': 'Uplink down',
	'maxElevation': 'Max predicted elevation'
} as const;

const allotmentTypes = [
	{
		id: "PLANNED",
		name: "Planned maintenance",
		description: "Planned maintenance known in advance."
	},
	{
		id: "UNPLANNED",
		name: "Unplanned downtime",
		description: "Unexpected downtime due to unknown errors or malfunctions."
	}
] as const;

const allotmentDefinitions = {
	kinds: [
		{ id: 'TENANT', name: 'Tenant' },
		{ id: 'SYSTEM', name: 'Downtime' },
		{ id: 'DEDICATED', name: 'Dedicated' },
		{ id: 'VISIBILITY', name: 'Visibility' },
		{ id: 'PARTNER', name: 'Partner' }
	],
	states: [
		{ name: "Staged", id: "STAGED" },
		{ name: "Created", id: "CREATED" },
		{ name: "Deprecated", id: "DEPRECATED" },
		{ name: "Removed", id: "REMOVED" }
	],
	icons: [
		{ id: 'TENANT', name: 'tenant' },
		{ id: 'SYSTEM', name: '16/stationStatus/down' }
	],
	classes: [
		{ id: 'TENANT', name: 'tenant-allotment' },
		{ id: 'SYSTEM', name: 'downtime-allotment' },
		{ id: 'DEDICATED', name: 'orther-allotment' },
		{ id: 'VISIBILITY', name: 'orther-allotment' },
		{ id: 'PARTNER', name: 'orther-allotment' }
	]
} as const;

const issueCategories = [
	{ id: 'SYSTEM_POWER', name: 'System power' },
	{ id: 'SYSTEM_CONNECTIVITY', name: 'System connectivity' },
	{ id: 'RFI', name: 'RFI' },
	{ id: 'ACU', name: 'ACU' },
	{ id: 'BACKEND', name: 'Backend' },
	{ id: 'DATA_TRANSFER', name: 'Data transfer' },
	{ id: 'SCHEDULING', name: 'Scheduling' },
	{ id: 'KSAT_OTHER', name: 'Ksat other' },
	{ id: 'CUSTOMER_OTHER', name: 'Customer' },
	{ id: 'EPHEMERIS', name: 'Ephemeris' },
	{ id: 'DEPENDENCY', name: 'Dependency' },
	{ id: 'MONITORING', name: 'Monitoring' },
	{ id: 'CONTROL', name: 'Control' }
] as const;

const internalKsatTenants = [
	'c4a1f83d-252a-4bf2-b57d-f84e0ba94cfe',
	'ba6d4868-9f5b-4481-91b9-13710c1922b8'] as const; // Internal tenants not connected to nominal operations. "Lite" and "Devops" tenants

const missionProfileKinds = [
	{ id: 'ORIGINAL', name: 'ORIGINAL' },
	{ id: 'PARAMETRIC', name: 'PARAMETRIC' }
] as const;

const jsonSchemaResources = {
	missionProfile: { id: 'MISSION_PROFILE_PARAMETERIZATION', name: 'Mission profile parameterization' },
	antenna: { id: 'ANTENNA', name: 'Antenna' }
	// spacecraft: { id: 'SPACECRAFT', name: 'Spacecraft' }, // Not used yet - comment back in when needed
} as const;

export {
	bandsDefinitions,
	tiers,
	lifecycleStates,
	issueCategories,
	contactStates,
	contactSignatureOutcomes,
	principalKinds,
	principalTags,
	apiServerNameMapping,
	systemResourcePermissions,
	spacecraftDefinitions,
	horizonProfile,
	azimuthFormat,
	trackingPredictionFormat,
	trackingPredictionSummaryFormat,
	allotmentTypes,
	allotmentDefinitions,
	frontendStates,
	nonCancelledStates,
	internalKsatTenants,
	missionProfileKinds,
	jsonSchemaResources,
	contactCart
};
