import _ from 'lodash';
import { isOperational } from './lifecycle';
import { DateTime } from 'luxon';
import store from '@/store/store';
import { $time } from './time';
import { extendContact, toApiContact } from '@/store/modules/contact';
import { fetchContactPrediction } from '@/js/api/predictions';

/*
   TODO:
   The logic in the plugin needs a big refactoring/rewiting.
   For instance there is some ambiguity about contact/allotment when finding conflicts in schedules.
   Allotments and contact should both be a 'scheduleEntity' (with padded start/end times etc. but its own properties).
   This work should be done when rewriting the 'contacts data workflow'.
*/

const generateScheduleForSystem = (contact, contacts, allotments = [], partnerAllocations = []) => {
	const schedule = contacts.concat(allotments, partnerAllocations).filter(scheduledEntity => scheduledEntity.system.id === contact.system.id);
	const disableForMigration = !!(store.getters['environment/isProductionEnvironment'] || store.getters['environment/isStagingEnvironment']) && !store.getters['migratedAssets/systemIsMigrated'](contact.system);

	const { timeslots, conflicts, conflictLevel } = conflictAnalyse(contact, schedule);
	return { system: contact.system, schedule, contact, alternatives: timeslots, conflicts, conflictLevel, disableForMigration };
};

// Intersect utilities
const isOverlapping = (contactA, contactB) => {
	return contactA.startTimePadded <= contactB.endTimePadded &&
		contactA.endTimePadded >= contactB.startTimePadded;
};

const adjustScheduleWithCartModifications = (schedule, contactCart) => {
	const movedContacts = contactCart.movedContacts.map(contact => ({ ...contact, system: contact.selectedSystem }));
	const modifiedContacts = [...contactCart.createdContacts, ...contactCart.cancelledContacts, ...movedContacts];
	const scheduleWithoutModified = schedule.filter(contact => modifiedContacts.every(cont => cont.id !== contact.id));
	return [...scheduleWithoutModified, ...contactCart.createdContacts, ...movedContacts];
};

/*
 * The purpose of conflictAnalyse is to determine if a contact (extendedContact) is overlapping
 * any contacts in a schedule (schedule). If there is a conflict it will try to find possible open
 * timeslots in the same time window as the original contact larger than a set time (minTimeslotLength).
 *
 * The function returns an object with possible alternative timeslots (timeslots) and a list of conflict
 * durations (conflicts) that is the time periods within the time window from the original contact where
 * the schedule is already filled with another contact. The conflictLevel (conflictLevel) is an integer
 * value determining the severity of the conflict ( no conflict: 0, conflict with alternative timeslots: 1,
 * conflicts without alternative timeslots: 2)
 */
const conflictLevel = { low: 0, medium: 1, high: 2 };
const contactPadding = 1; // 1 second padding is needed to satisfy kogs restraints.

// TODO: who decides minTimeslotLength value? setting or globally defined?
const conflictAnalyse = (extendedContact, schedule, minTimeslotLength = 1) => {
	// initial return values
	const timeslots = [];
	const conflicts = [];

	// generate list conflicting contacts
	const conflictingContacts = _.chain(schedule)
		.filter(({ state }) => state !== 'CANCELLED')
		.filter(contact => isOverlapping(extendedContact, contact))
		.sortBy(['startTimePadded'])
		.value();
	// if no conflicting contacts no more work is needed
	if (!conflictingContacts.length) {
		return { timeslots, conflicts, conflictLevel: conflictLevel.low };
	}

	// Define helper functions to add conflicts and timeslots
	const addConflict = (startTime, endTime, contact) => conflicts.push({
		contact,
		startTime,
		endTime,
		duration: endTime.toSeconds() - startTime.toSeconds()
	});
	const addTimeslot = (startTimePadded, endTimePadded) => {
		const startTime = DateTime.fromSeconds(startTimePadded.toSeconds() + setupDuration + contactPadding).toUTC();
		const endTime = DateTime.fromSeconds(endTimePadded.toSeconds() - teardownDuration - contactPadding).toUTC();
		timeslots.push({
			...extendedContact,
			startTime,
			endTime,
			duration: endTime.toSeconds() - startTime.toSeconds(),
			startTimePadded,
			endTimePadded,
			durationPadded: endTimePadded.toSeconds() - startTimePadded.toSeconds()
		});
	};


	// initialize algorithm
	const { startTimePadded, endTimePadded, setupDuration, teardownDuration } = extendedContact;
	const minTimeslotPadded = (setupDuration + teardownDuration + minTimeslotLength) * 1000;

	// since the algorithm looks at the gaps between contacts a stop element is needed to create the last gap.
	conflictingContacts.push({ startTimePadded: endTimePadded, endTimePadded });

	let gapStart = startTimePadded;
	let conflictStart = startTimePadded;

	let ongoingContact = null;
	// main algorithm loop
	conflictingContacts.forEach((contact, index) => {
		// calculate duration of gap between contacts in schedule
		const gapEnd = contact.startTimePadded;
		const gapDuration = gapEnd - gapStart;

		// if the gap is larger enough to fit an alternative timeslot add it to the list of timeslots
		const timeslotFitsInGap = gapDuration > minTimeslotPadded;
		if (timeslotFitsInGap) {
			addTimeslot(gapStart, gapEnd);
			// if a conflict is ongoing it will end when a timeslot is added. Then the conflict is added to the list of contacts
			const hasOngoingConflict = gapStart - conflictStart > 0;
			if (hasOngoingConflict) {
				addConflict(conflictStart, gapStart, ongoingContact);
			}
			// after an alternative timeslot is added a new conflict will start
			conflictStart = gapEnd;
		}

		if (index < conflictingContacts.length - 1) {
			gapStart = contact.endTimePadded;
			ongoingContact = contact;
		}
	});

	// finalize the algorithm by adding a possible ongoing conflict to the list of conflicts
	const hasOngoingConflict = endTimePadded - conflictStart > 0;
	if (hasOngoingConflict) {
		addConflict(
			conflictStart,
			endTimePadded,
			ongoingContact
		);
	}

	return {
		timeslots: timeslots.sort((a, b) => b.duration - a.duration),
		conflicts,
		conflictLevel: timeslots.length ? conflictLevel.medium : conflictLevel.high
	};
};

const isHistoric = contact => DateTime.utc() > contact.startTimePadded;

const isEditable = contact => {
	const timeThreshold = DateTime.min(contact.startTime.minus({ minutes: 1, seconds: 10 }), contact.startTimePadded.minus({ seconds: 10 }));
	return !(contact.extendedState.isCancelled() || contact.extendedState.isCommitted()) && DateTime.utc() < timeThreshold;
};

const isSignable = contact => {
	return isHistoric(contact);
};

const formatScheduleReply = contact => {
	const start = contact.startTime;
	const end = contact.endTime;
	const system = contact.system.name;
	const spacecraft = contact.spacecraft.name;
	return `${start.toISODate()} ${system} ${spacecraft} ${$time.formatTime(start)}-${$time.formatTime(end)}`.trim();
};

const formatAsCSV = contact => {
	const start = contact.startTime;
	const end = contact.endTime;
	const system = contact.system.name;
	const spacecraft = contact.spacecraft.name;

	// get pass elevation
	let passPred = 'Not found';
	if (contact?.ephemeris_id) {
		return fetchContactPrediction([contact.ephemeris_id], [contact.system.station], contact.startTime, contact.endTime)
			.then(predicted => {
				if (predicted.predictions?.length == 1) {
					const contactPrediction = predicted.predictions[0] ?? null;
					try {
						passPred = `${Math.round(contactPrediction?.maximumElevation * 100) / 100}°`;
					} catch (error) {
						console.error(error);
					}
				}
				return [start.toISODate(),
					$time.formatTime(start),
					$time.formatTime(end),
					system,
					spacecraft,
					contact.missionProfile.name,
					passPred
				].join(',').trim();
			});
	}
	return Promise.resolve([start.toISODate(),
		$time.formatTime(start),
		$time.formatTime(end),
		system,
		spacecraft,
		contact.missionProfile.name,
		passPred
	].join(',').trim());
};

const getSharedTenant = contacts => {
	const tenantIds = [...new Set(contacts.map(contact => contact.tenant.id))];
	return tenantIds.length === 1 ? store.getters['tenants/find'](tenantIds[0]) : null;
};

const getSharedStation = contacts => {
	const stationIds = [...new Set(contacts.map(contact => contact.system.station))];
	return stationIds.length === 1 ? store.getters['stations/find'](stationIds[0]) : null;
};

const getSharedSystem = contacts => {
	const systemIds = [...new Set(contacts.map(contact => contact.system.id))];
	return systemIds.length === 1 ? store.getters['systems/find'](systemIds[0]) : null;
};

const getPossibleSystems = (contact) => {
	const onTenantSystems = contact => systemID => {
		const tenant = store.getters['tenants/find'](contact.missionProfile.tenant);
		return tenant.systems.includes(systemID);
	};

	return _.chain(contact.missionProfile.systems)
		.filter(onTenantSystems(contact))
		.mapStoreIds('systems')
		.filter(system => contact.station.id === system.station)
		.filter(system => isOperational(system))
		.value();
};

const getAllowedSystems = (contacts, schedule) => {
	const sharedStation = getSharedStation(contacts);

	const onTenantSystems = contact => systemID => {
		const tenant = store.getters['tenants/find'](contact.missionProfile.tenant);
		return tenant.systems.includes(systemID);
	};

	const onNoConflictWith = contact => system => {
		const downtimes = store.getters['downtimes/all'];
		const partnerAllocations = store.getters['partnerAllocations/all'];
		return !schedule
			.filter(contact => !contact.extendedState?.isCancelled())
			.concat(downtimes, partnerAllocations)
			.some(scheduleEntity =>
				contact.id !== scheduleEntity.id &&
				system.id === scheduleEntity.system.id &&
				isOverlapping(contact, scheduleEntity)
			);
	};

	return contacts.map(contact => _.chain(contact.missionProfile.systems)
		.filter(onTenantSystems(contact))
		.mapStoreIds('systems')
		.filter(system => sharedStation?.id === system.station)
		.filter(onNoConflictWith(contact, schedule))
		.filter(system => {
			return store.getters['environment/isProductionEnvironment'] || store.getters['environment/isStagingEnvironment'] ? !!store.getters['migratedAssets/systemIsMigrated'](system) : true;
		})
		.value()
	);
};

const getSelectableSystems = (contacts, schedule) => {
	const allowedSystems = getAllowedSystems(contacts, schedule);
	const sharedSystem = getSharedSystem(contacts);

	const onSharedSystems = system => allowedSystems
		.every(systemList => systemList.some(({ id }) => id === system.id));

	return allowedSystems[0].filter(system => onSharedSystems(system, allowedSystems))
		.filter(system => !sharedSystem || sharedSystem.id !== system.id)
		.filter(system => isOperational(system));
};

const handleOverlaps = (contact, schedule, minTrimRate) => {
	const contactSchedule = schedule.filter(cont => cont.id !== contact.id);
	const minLength = Math.ceil(contact.duration * minTrimRate / 100.0);
	const possibleSystems = getPossibleSystems(contact);

	const analyzedSystems = possibleSystems.map(system => {
		const systemSchedule = contactSchedule.filter(cont => cont.system.id === system.id);
		return {
			system,
			...conflictAnalyse(contact, systemSchedule, minLength)
		};
	});

	const longestTimeslotsPerSystem = analyzedSystems.map(analysis => {
		const longestTimeslotForSystem = _.maxBy(analysis.timeslots, 'duration');
		return {
			...longestTimeslotForSystem,
			system: analysis.system
		};
	});

	const longestTimeslot = _.maxBy(longestTimeslotsPerSystem, 'duration');

	if (longestTimeslot) {
		return {
			...contact,
			conflictType: 'trimmable',
			trimmedTimeslot: longestTimeslot
		};
	}

	return {
		...contact,
		conflictType: 'cancellable'
	};
};

const fixedSeed = 1000 + Math.round(1000 * Math.random());
let sequence = fixedSeed;

const seededRand = (min, max) => {
	let x = Math.sin(sequence++) * 10000;
	x = x - Math.floor(x);
	return Math.floor(x * (max - min)) + min;
};

const addReallocateOptionsToContacts = (contacts, schedule, minTrimRate) => {
	sequence = fixedSeed; // Reseting random sequence to get the same systems every time and prevent jumping behaivour on update
	const occupied = [];

	return contacts.map(contact => {
		if (contact?.isAllotment) {
			return { ...contact, conflictType: 'allotment' };
		}
		if (isHistoric(contact)) {
			return { ...contact, conflictType: 'ongoing' };
		}
		if (!isEditable(contact)) {
			return { ...contact, conflictType: 'tooclose' };
		}

		let selectableSystems = getSelectableSystems([contact], schedule);

		if (!selectableSystems.length) {
			return handleOverlaps(contact, schedule, minTrimRate);
		}

		if (selectableSystems.some(system => system.dedicatedTo === contact.tenant.id)) {
			// If found, only use dedicated systems as selectable.
			selectableSystems = selectableSystems.filter(system => system.dedicatedTo === contact.tenant.id);
		} else if (selectableSystems.some(system => system.dedicatedTo)) {
			// If the system is dedicated to another tenant and other options are available, remove it from selectable systems
			const systemsDedicatedToOther = selectableSystems.filter(system => system.dedicatedTo);
			if (selectableSystems.length > systemsDedicatedToOther.length) {
				selectableSystems = selectableSystems.filter(system => !system.dedicatedTo);
			}
		}

		const selectableSystemsSorted = _.chain(selectableSystems)
			.sortByAlphaNum()
			.value();

		if (selectableSystemsSorted.every(system => occupied.includes(system.id))) {
			occupied.length = 0;
		}

		let selectedSystem = selectableSystemsSorted[seededRand(0, selectableSystemsSorted.length)];

		// Keeping track of which systems have been used for a more even distribution
		while (occupied.includes(selectedSystem.id)) {
			selectedSystem = selectableSystemsSorted[seededRand(0, selectableSystemsSorted.length)];
		}

		occupied.push(selectedSystem.id);

		return { ...contact, conflictType: 'movable', selectableSystems, selectedSystem };
	});
};

const updateContactTimes = (contact, newStartTime, newEndTime) => {
	contact.startTime = newStartTime;
	contact.endTime = newEndTime;
	contact.duration = newEndTime.toSeconds() - newStartTime.toSeconds();
	contact.durationPadded = contact.duration + contact.teardownDuration + contact.setupDuration;
	contact.startTimePadded = contact.startTime.minus({ seconds: contact.setupDuration });
	contact.endTimePadded = contact.endTime.plus({ seconds: contact.teardownDuration });
	return contact;
};

export default {
	install: app => {
		app.config.globalProperties.$contact = {
			extendContact,
			toApiContact,
			isHistoric,
			isEditable,
			isSignable,
			isOverlapping,
			formatScheduleReply,
			formatAsCSV,
			generateScheduleForSystem,
			adjustScheduleWithCartModifications,
			conflictAnalyse,
			getSharedTenant,
			getSharedStation,
			getSharedSystem,
			getAllowedSystems,
			getSelectableSystems,
			addReallocateOptionsToContacts,
			updateContactTimes
		};
	}
};
