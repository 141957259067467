import { state, mutations, actions, getters } from '@/store/build-module/common';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';
import store from '@/store/store';
import { lifecycleStates } from '@/js/utils/data-definitions';
import { DateTime } from 'luxon';

const module = 'downtimes';

const meta = {
	module,
	basePath: '/v1.0/downtimes',
	mapping: {
		start_time: 'startTime',
		end_time: 'endTime',
		lifecycle_state: 'lifecycleState',
		system_id: 'systemId'
	},
	dateFields: ['start_time', 'end_time'],
	scope: await getScope('kogs'),
	inputWrapper: input => ({ data: input })
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'add', 'update', 'find', 'remove'])
};

const extendDowntime = downtime => {
	const { startTime, endTime } = downtime;
	const duration = Math.round(endTime.toSeconds() - startTime.toSeconds());
	const system = store.getters['systems/find'](downtime.systemId);
	// startTimepadded and endTimePadded is assigned the corrosponding xTime since the xTimePadded values is nedded in conflictAnalyse.
	return {
		...downtime,
		system,
		startTimePadded: startTime,
		endTimePadded: endTime,
		duration,
		durationPadded: duration,
		isAllotment: true,
		setupDuration: 0,
		teardownDuration: 0
	};
};

const toApiDowntime = (downtime) => {
	return {
		id: downtime.id,
		type: downtime.type,
		systemId: downtime.systemId,
		startTime: downtime.startTime,
		endTime: downtime.endTime,
		reason: downtime.reason,
		lifecycleState: downtime.lifecycleState
	};
};

const baseGetters = getters(module);
const baseActions = actions(api);

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...baseGetters,

		all(state, getters, rootState) {
			return baseGetters.all(state, getters, rootState).filter(downtime => downtime.lifecycleState === lifecycleStates.CREATED.id)
				.map(extendDowntime);
		}
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...baseActions,

		create(context, downtime) {
			return baseActions.create(context, toApiDowntime(downtime));
		},

		update(context, downtime) {
			return baseActions.update(context, toApiDowntime(downtime));
		},

		endNow({ getters, dispatch }, id) {
			if (getters.none({ id })) {
				return Promise.reject(`Allotment ${id} not found`);
			}

			const allotment = getters.find(id);
			const now = DateTime.utc();
			const started = allotment.startTime < now;
			const ended = allotment.endTime < now;

			if (!started) {
				return Promise.reject(`Allotment ${id} has not started`);
			} else if (ended) {
				return Promise.reject(`Allotment ${id} has already ended`);
			}

			return dispatch('update', {
				...allotment,
				endTime: now
			});
		}
	}
};

export { extendDowntime };
